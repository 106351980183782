import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './locales'

Vue.use(VueI18n)

const locale = `en`

const i18n = new VueI18n({
  locale,
  messages,
})

Vue.i18n = i18n

// the below code snippet can be anbled to see if text has been correctly translated
// Vue.prototype.$t = (key: VueI18n.Path, values?: VueI18n.Values) => `✅${i18n.t(key, values)}`
// Vue.prototype.$tc = (key: VueI18n.Path, choice?: VueI18n.Choice, values?: VueI18n.Values) => `✅${i18n.tc(key, choice, values)}`

export default i18n
