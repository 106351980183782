/**
 * When using this mixin add ref="form_",
 * and v-model="isFormValid_" to your form
 */

import Vue, {
  VueConstructor,
} from 'vue'
import {
  VuetifyForm,
} from '@/plugins/vuetify/types'

export default (Vue as VueConstructor<
  Vue & {
    $refs: {
      form_: VuetifyForm
    }
  }
>).extend({
  data: () => ({
    isFormValid_: true as boolean,
  }),
  methods: {
    resetForm () {
      if (!this.$refs.form_) return
      return this.$refs.form_.reset()
    },
    validateForm (): boolean {
      if (!this.$refs.form_) return true
      return this.$refs.form_.validate()
    },
    resetFormValidation () {
      if (!this.$refs.form_) return
      return this.$refs.form_.resetValidation()
    },
  },
})
