














import Vue from 'vue'
import NavigationDrawer from '@/components/Core/NavigationDrawer.vue'
import Snackbar from '@/components/Core/Snackbar.vue'
import Toolbar from '@/components/Core/Toolbar.vue'
import firebase from '@/plugins/firebase'
import {
  authStore,
} from '@/store'

export default Vue.extend({
  name: `App`,
  components: {
    NavigationDrawer,
    Snackbar,
    Toolbar,
  },
  data: () => ({
    drawer: null as boolean | null,
  }),
  computed: {
    user (): firebase.User | null {
      return authStore.user
    },
  },
})
