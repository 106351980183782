
import {
  Id,
} from '@/types/base'
import {
  Word,
} from '@/types/word'
import store from '@/store/main'
import {
  Action, getModule, Module, Mutation, VuexModule,
} from 'vuex-module-decorators'
import {
  wordApi,
} from '@/api/database'
import {
  updateDatabaseStoreArray,
} from './util'

const storeName = `word`

@Module({
  namespaced: true,
  name: storeName,
  store,
  dynamic: true,
})
class WordsModule extends VuexModule {
  // state
  private words_: (Word & Id)[] = [
  ]

  private isLoadingCreate_ = false

  private isLoadingDelete_ = false

  private isLoadingGet_ = false

  private isLoadingGetAll_ = false

  private isLoadingUpdate_ = false

  // getters
  get data (): (Word & Id)[] {
    return this.words_
  }

  get dataById () {
    return (id: string) => this.words_.find((word) => word.id === id)
  }

  get isLoadingCreate (): boolean {
    return this.isLoadingCreate_
  }

  get isLoadingDelete (): boolean {
    return this.isLoadingDelete_
  }

  get isLoadingGet (): boolean {
    return this.isLoadingGet_
  }

  get isLoadingGetAll (): boolean {
    return this.isLoadingGetAll_
  }

  get isLoadingUpdate (): boolean {
    return this.isLoadingUpdate_
  }

  // mutations
  @Mutation
  private delete_ (id: string) {
    const found = this.words_.find((w) => w.id === id)
    if (!found) return
    const index = this.words_.indexOf(found)
    this.words_.splice(index, 1)
    // this.words_ = this.words_.slice(index, 1)
  }

  @Mutation
  private set_ (word: (Word & Id)) {
    this.words_ = updateDatabaseStoreArray(this.words_, word)
  }

  @Mutation
  private setAll_ (words: (Word & Id)[]) {
    for (const word of words) {
      this.words_ = updateDatabaseStoreArray(this.words_, word)
    }
  }

  @Mutation
  private setIsLoadingCreate_ (status: boolean) {
    this.isLoadingCreate_ = status
  }

  @Mutation
  private setIsLoadingDelete_ (status: boolean) {
    this.isLoadingDelete_ = status
  }

  @Mutation
  private setIsLoadingGet_ (status: boolean) {
    this.isLoadingGet_ = status
  }

  @Mutation
  private setIsLoadingGetAll_ (status: boolean) {
    this.isLoadingGetAll_ = status
  }

  @Mutation
  private setIsLoadingUpdate_ (status: boolean) {
    this.isLoadingUpdate_ = status
  }

  // actions
  @Action({
    rawError: true,
  })
  async create (word: Word): Promise<void> {
    this.setIsLoadingCreate_(true)
    const newWord = await wordApi.create(word)
    this.set_(newWord)
    store.cache.delete(`${storeName}/forceGetAll`)
    this.setIsLoadingCreate_(false)
  }

  @Action({
    rawError: true,
  })
  async delete (id: string): Promise<void> {
    this.setIsLoadingDelete_(true)
    await wordApi.delete(id)
    this.delete_(id)
    store.cache.delete(`${storeName}/forceGet`, id)
    store.cache.delete(`${storeName}/forceGetAll`)
    this.setIsLoadingDelete_(false)
  }

  @Action({
    rawError: true,
  })
  async forceGet (id: string): Promise<(Word & Id) | undefined> {
    this.setIsLoadingGet_(true)
    const word = this.dataById(id) || await wordApi.get(id)
    if (word) this.set_(word)
    this.setIsLoadingGet_(false)
    return word
  }

  @Action({
    rawError: true,
  })
  async get (id: string): Promise<void> {
    const word = <Word & Id>(await store.cache.dispatch(`${storeName}/forceGet`, id))
    this.set_(word)
  }

  @Action({
    rawError: true,
  })
  async forceGetAll (): Promise<(Word & Id)[]> {
    this.setIsLoadingGetAll_(true)
    const words = await wordApi.getAll()
    this.setAll_(words)
    this.setIsLoadingGetAll_(false)
    return words
  }

  @Action({
    rawError: true,
  })
  async getAll (): Promise<(Word & Id)[]> {
    return store.cache.dispatch(`${storeName}/forceGetAll`)
  }

  @Action({
    rawError: true,
  })
  async loadList (ids: string[]): Promise<void> {
    const idsToLoad = ids.filter((id) => !this.dataById(id))
    const words = await wordApi.getList(idsToLoad)
    this.setAll_(words)
  }

  @Action({
    rawError: true,
  })
  async update (updatedWord: (Word & Id)): Promise<void> {
    this.setIsLoadingUpdate_(true)
    const {
      id, ...word
    } = updatedWord
    await wordApi.update(id, word)
    this.set_(updatedWord)
    store.cache.delete(`${storeName}/forceGet`, id)
    store.cache.delete(`${storeName}/forceGetAll`)
    this.setIsLoadingUpdate_(false)
  }
}

const words = getModule(WordsModule)

export default words
