export const loadScript = (src: string): Promise<any> => {
  if (document.querySelector(`script[src="${src}"]`)) return Promise.resolve()
  return new Promise((resolve, reject) => {
    const script = document.createElement(`script`)
    script.src = src
    script.onload = resolve
    script.onerror = reject
    document.head.appendChild(script)
  })
}

export const chunkArray = (array: any[], size = 10): any[][] => {
  const slicedArray = [
  ]
  for (let i = 0; i < array.length; i += size) {
    slicedArray.push(array.slice(i, i + size))
  }
  return slicedArray
}
