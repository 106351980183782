
import {
  Id,
} from '@/types/base'
import {
  Lesson,
} from '@/types/lesson'
import store from '@/store/main'
import {
  Action, getModule, Module, Mutation, VuexModule,
} from 'vuex-module-decorators'
import {
  lessonApi,
} from '@/api/database'
import {
  updateDatabaseStoreArray,
} from './util'

const storeName = `lesson`

@Module({
  namespaced: true,
  name: storeName,
  store,
  dynamic: true,
})
class LessonsModule extends VuexModule {
  // state
  private lessons_: (Lesson & Id)[] = [
  ]

  private isLoadingCreate_ = false

  private isLoadingDelete_ = false

  private isLoadingGet_ = false

  private isLoadingGetAll_ = false

  private isLoadingUpdate_ = false

  // getters
  get data (): (Lesson & Id)[] {
    return this.lessons_
  }

  get dataById () {
    return (id: string) => this.lessons_.find((lesson) => lesson.id === id)
  }

  get isLoadingCreate (): boolean {
    return this.isLoadingCreate_
  }

  get isLoadingDelete (): boolean {
    return this.isLoadingDelete_
  }

  get isLoadingGet (): boolean {
    return this.isLoadingGet_
  }

  get isLoadingGetAll (): boolean {
    return this.isLoadingGetAll_
  }

  get isLoadingUpdate (): boolean {
    return this.isLoadingUpdate_
  }

  // mutations
  @Mutation
  private delete_ (id: string) {
    const found = this.lessons_.find((w) => w.id === id)
    if (!found) return
    const index = this.lessons_.indexOf(found)
    this.lessons_.splice(index, 1)
    // this.lessons_ = this.lessons_.slice(index, 1)
  }

  @Mutation
  private set_ (lesson: (Lesson & Id)) {
    this.lessons_ = updateDatabaseStoreArray(this.lessons_, lesson)
  }

  @Mutation
  private setAll_ (lessons: (Lesson & Id)[]) {
    for (const lesson of lessons) {
      this.lessons_ = updateDatabaseStoreArray(this.lessons_, lesson)
    }
  }

  @Mutation
  private setIsLoadingCreate_ (status: boolean) {
    this.isLoadingCreate_ = status
  }

  @Mutation
  private setIsLoadingDelete_ (status: boolean) {
    this.isLoadingDelete_ = status
  }

  @Mutation
  private setIsLoadingGet_ (status: boolean) {
    this.isLoadingGet_ = status
  }

  @Mutation
  private setIsLoadingGetAll_ (status: boolean) {
    this.isLoadingGetAll_ = status
  }

  @Mutation
  private setIsLoadingUpdate_ (status: boolean) {
    this.isLoadingUpdate_ = status
  }

  // actions
  @Action({
    rawError: true,
  })
  async create (lesson: Lesson): Promise<void> {
    this.setIsLoadingCreate_(true)
    const newLesson = await lessonApi.create(lesson)
    this.set_(newLesson)
    store.cache.delete(`${storeName}/forceGetAll`)
    this.setIsLoadingCreate_(false)
  }

  @Action({
    rawError: true,
  })
  async delete (id: string): Promise<void> {
    this.setIsLoadingDelete_(true)
    await lessonApi.delete(id)
    this.delete_(id)
    store.cache.delete(`${storeName}/forceGet`, id)
    store.cache.delete(`${storeName}/forceGetAll`)
    this.setIsLoadingDelete_(false)
  }

  @Action({
    rawError: true,
  })
  async forceGet (id: string): Promise<(Lesson & Id) | undefined> {
    this.setIsLoadingGet_(true)
    const lesson = this.dataById(id) || await lessonApi.get(id)
    if (lesson) this.set_(lesson)
    this.setIsLoadingGet_(false)
    return lesson
  }

  @Action({
    rawError: true,
  })
  async get (id: string): Promise<void> {
    const lesson = <Lesson & Id>(await store.cache.dispatch(`${storeName}/forceGet`, id))
    this.set_(lesson)
  }

  @Action({
    rawError: true,
  })
  async forceGetAll (): Promise<(Lesson & Id)[]> {
    this.setIsLoadingGetAll_(true)
    const lessons = await lessonApi.getAll()
    this.setAll_(lessons)
    this.setIsLoadingGetAll_(false)
    return lessons
  }

  @Action({
    rawError: true,
  })
  async getAll (): Promise<(Lesson & Id)[]> {
    return store.cache.dispatch(`${storeName}/forceGetAll`)
  }

  @Action({
    rawError: true,
  })
  async loadList (ids: string[]): Promise<void> {
    const idsToLoad = ids.filter((id) => !this.dataById(id))
    const lessons = await lessonApi.getList(idsToLoad)
    this.setAll_(lessons)
  }

  @Action({
    rawError: true,
  })
  async update (updatedLesson: (Lesson & Id)): Promise<void> {
    this.setIsLoadingUpdate_(true)
    const {
      id, ...lesson
    } = updatedLesson
    await lessonApi.update(id, lesson)
    this.set_(updatedLesson)
    store.cache.delete(`${storeName}/forceGet`, id)
    store.cache.delete(`${storeName}/forceGetAll`)
    this.setIsLoadingUpdate_(false)
  }
}

const lessons = getModule(LessonsModule)

export default lessons
