import firebase, {
  db,
} from '@/plugins/firebase'
import {
  Id,
} from '@/types/base'
import {
  Place,
} from '@/types/place'

const converter: firebase.firestore.FirestoreDataConverter<Place> = {
  toFirestore: (place: Place) => place,
  fromFirestore (snapshot: firebase.firestore.QueryDocumentSnapshot, options: firebase.firestore.SnapshotOptions): (Place & Id) {
    const place = <Place>snapshot.data(options)
    return {
      id: snapshot.id,
      ...place,
    }
  },
}

class PlaceApi {
  async create (place: Place): Promise<(Place & Id)> {
    const ref = await db.collection(`places`)
      .withConverter(converter)
      .add(place)
    return {
      id: ref.id,
      ...place,
    }
  }

  async delete (id: string): Promise<void> {
    return db.doc(`places/${id}`)
      .delete()
  }

  async get (id: string): Promise<(Place & Id) | undefined> {
    const snapshot = await db.doc(`places/${id}`)
      .withConverter(converter)
      .get()
    const data = snapshot.data()
    if (!data) return
    return <(Place & Id)>data
  }

  async getAll (): Promise<(Place & Id)[]> {
    const snapshot = await db.collection(`places`)
      .withConverter(converter)
      .get()
    return snapshot.docs.map((doc) => <(Place & Id)>doc.data())
  }

  async update (id: string, place: Place): Promise<void> {
    return db.doc(`places/${id}`)
      .withConverter(converter)
      .update(place)
  }
}

export default new PlaceApi()
