
import {
  Id,
} from '@/types/base'
import {
  Place,
} from '@/types/place'
import store from '@/store/main'
import {
  Action, getModule, Module, Mutation, VuexModule,
} from 'vuex-module-decorators'
import {
  placeApi,
} from '@/api/database'
import {
  updateDatabaseStoreArray,
} from './util'

const storeName = `place`

@Module({
  namespaced: true,
  name: storeName,
  store,
  dynamic: true,
})
class PlacesModule extends VuexModule {
  // state
  private places_: (Place & Id)[] = [
  ]

  private isLoadingCreate_ = false

  private isLoadingDelete_ = false

  private isLoadingGet_ = false

  private isLoadingGetAll_ = false

  private isLoadingUpdate_ = false

  // getters
  get data (): (Place & Id)[] {
    return this.places_
  }

  get dataById () {
    return (id: string) => this.places_.find((place) => place.id === id)
  }

  get isLoadingCreate (): boolean {
    return this.isLoadingCreate_
  }

  get isLoadingDelete (): boolean {
    return this.isLoadingDelete_
  }

  get isLoadingGet (): boolean {
    return this.isLoadingGet_
  }

  get isLoadingGetAll (): boolean {
    return this.isLoadingGetAll_
  }

  get isLoadingUpdate (): boolean {
    return this.isLoadingUpdate_
  }

  // mutations
  @Mutation
  private delete_ (id: string) {
    const found = this.places_.find((w) => w.id === id)
    if (!found) return
    const index = this.places_.indexOf(found)
    this.places_.splice(index, 1)
    // this.places_ = this.places_.slice(index, 1)
  }

  @Mutation
  private set_ (place: (Place & Id)) {
    this.places_ = updateDatabaseStoreArray(this.places_, place)
  }

  @Mutation
  private setAll_ (places: (Place & Id)[]) {
    for (const place of places) {
      this.places_ = updateDatabaseStoreArray(this.places_, place)
    }
  }

  @Mutation
  private setIsLoadingCreate_ (status: boolean) {
    this.isLoadingCreate_ = status
  }

  @Mutation
  private setIsLoadingDelete_ (status: boolean) {
    this.isLoadingDelete_ = status
  }

  @Mutation
  private setIsLoadingGet_ (status: boolean) {
    this.isLoadingGet_ = status
  }

  @Mutation
  private setIsLoadingGetAll_ (status: boolean) {
    this.isLoadingGetAll_ = status
  }

  @Mutation
  private setIsLoadingUpdate_ (status: boolean) {
    this.isLoadingUpdate_ = status
  }

  // actions
  @Action({
    rawError: true,
  })
  async create (place: Place): Promise<void> {
    this.setIsLoadingCreate_(true)
    const newPlace = await placeApi.create(place)
    this.set_(newPlace)
    store.cache.delete(`${storeName}/forceGetAll`)
    this.setIsLoadingCreate_(false)
  }

  @Action({
    rawError: true,
  })
  async delete (id: string): Promise<void> {
    this.setIsLoadingDelete_(true)
    await placeApi.delete(id)
    this.delete_(id)
    store.cache.delete(`${storeName}/forceGet`, id)
    store.cache.delete(`${storeName}/forceGetAll`)
    this.setIsLoadingDelete_(false)
  }

  @Action({
    rawError: true,
  })
  async forceGet (id: string): Promise<(Place & Id) | undefined> {
    this.setIsLoadingGet_(true)
    const place = this.dataById(id) || await placeApi.get(id)
    if (place) this.set_(place)
    this.setIsLoadingGet_(false)
    return place
  }

  @Action({
    rawError: true,
  })
  async get (id: string): Promise<void> {
    const place = <Place & Id>(await store.cache.dispatch(`${storeName}/forceGet`, id))
    this.set_(place)
  }

  @Action({
    rawError: true,
  })
  async forceGetAll (): Promise<(Place & Id)[]> {
    this.setIsLoadingGetAll_(true)
    const places = await placeApi.getAll()
    this.setAll_(places)
    this.setIsLoadingGetAll_(false)
    return places
  }

  @Action({
    rawError: true,
  })
  async getAll (): Promise<(Place & Id)[]> {
    return store.cache.dispatch(`${storeName}/forceGetAll`)
  }

  @Action({
    rawError: true,
  })
  async update (updatedPlace: (Place & Id)): Promise<void> {
    this.setIsLoadingUpdate_(true)
    const {
      id, ...place
    } = updatedPlace
    await placeApi.update(id, place)
    this.set_(updatedPlace)
    store.cache.delete(`${storeName}/forceGet`, id)
    store.cache.delete(`${storeName}/forceGetAll`)
    this.setIsLoadingUpdate_(false)
  }
}

const places = getModule(PlacesModule)

export default places
