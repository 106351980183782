import {
  Id,
} from "@/types/base"

export function updateDatabaseStoreArray<T extends Id> (array: T[], item: T): T[] {
  const found = array.find((d) => d.id === item.id)
  if (!found) {
    array.push(item)
    return array
  }
  const index = array.indexOf(found)
  array[index] = item
  return array.slice()
}
