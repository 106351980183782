
import {
  Id,
} from '@/types/base'
import {
  Chapter,
} from '@/types/chapter'
import store from '@/store/main'
import {
  Action, getModule, Module, Mutation, VuexModule,
} from 'vuex-module-decorators'
import {
  chapterApi,
} from '@/api/database'
import {
  updateDatabaseStoreArray,
} from './util'

const storeName = `chapter`

@Module({
  namespaced: true,
  name: storeName,
  store,
  dynamic: true,
})
class ChaptersModule extends VuexModule {
  // state
  private chapters_: (Chapter & Id)[] = [
  ]

  private isLoadingCreate_ = false

  private isLoadingDelete_ = false

  private isLoadingGet_ = false

  private isLoadingGetAll_ = false

  private isLoadingUpdate_ = false

  // getters
  get data (): (Chapter & Id)[] {
    return this.chapters_
  }

  get dataById () {
    return (id: string) => this.chapters_.find((chapter) => chapter.id === id)
  }

  get isLoadingCreate (): boolean {
    return this.isLoadingCreate_
  }

  get isLoadingDelete (): boolean {
    return this.isLoadingDelete_
  }

  get isLoadingGet (): boolean {
    return this.isLoadingGet_
  }

  get isLoadingGetAll (): boolean {
    return this.isLoadingGetAll_
  }

  get isLoadingUpdate (): boolean {
    return this.isLoadingUpdate_
  }

  // mutations
  @Mutation
  private delete_ (id: string) {
    const found = this.chapters_.find((w) => w.id === id)
    if (!found) return
    const index = this.chapters_.indexOf(found)
    this.chapters_.splice(index, 1)
    // this.chapters_ = this.chapters_.slice(index, 1)
  }

  @Mutation
  private set_ (chapter: (Chapter & Id)) {
    this.chapters_ = updateDatabaseStoreArray(this.chapters_, chapter)
  }

  @Mutation
  private setAll_ (chapters: (Chapter & Id)[]) {
    for (const chapter of chapters) {
      this.chapters_ = updateDatabaseStoreArray(this.chapters_, chapter)
    }
  }

  @Mutation
  private setIsLoadingCreate_ (status: boolean) {
    this.isLoadingCreate_ = status
  }

  @Mutation
  private setIsLoadingDelete_ (status: boolean) {
    this.isLoadingDelete_ = status
  }

  @Mutation
  private setIsLoadingGet_ (status: boolean) {
    this.isLoadingGet_ = status
  }

  @Mutation
  private setIsLoadingGetAll_ (status: boolean) {
    this.isLoadingGetAll_ = status
  }

  @Mutation
  private setIsLoadingUpdate_ (status: boolean) {
    this.isLoadingUpdate_ = status
  }

  // actions
  @Action({
    rawError: true,
  })
  async create (chapter: Chapter): Promise<void> {
    this.setIsLoadingCreate_(true)
    const newChapter = await chapterApi.create(chapter)
    this.set_(newChapter)
    store.cache.delete(`${storeName}/forceGetAll`)
    this.setIsLoadingCreate_(false)
  }

  @Action({
    rawError: true,
  })
  async delete (id: string): Promise<void> {
    this.setIsLoadingDelete_(true)
    await chapterApi.delete(id)
    this.delete_(id)
    store.cache.delete(`${storeName}/forceGet`, id)
    store.cache.delete(`${storeName}/forceGetAll`)
    this.setIsLoadingDelete_(false)
  }

  @Action({
    rawError: true,
  })
  async forceGet (id: string): Promise<(Chapter & Id) | undefined> {
    this.setIsLoadingGet_(true)
    const chapter = this.dataById(id) || await chapterApi.get(id)
    if (chapter) this.set_(chapter)
    this.setIsLoadingGet_(false)
    return chapter
  }

  @Action({
    rawError: true,
  })
  async get (id: string): Promise<void> {
    const chapter = <Chapter & Id>(await store.cache.dispatch(`${storeName}/forceGet`, id))
    this.set_(chapter)
  }

  @Action({
    rawError: true,
  })
  async forceGetAll (): Promise<(Chapter & Id)[]> {
    this.setIsLoadingGetAll_(true)
    const chapters = await chapterApi.getAll()
    this.setAll_(chapters)
    this.setIsLoadingGetAll_(false)
    return chapters
  }

  @Action({
    rawError: true,
  })
  async getAll (): Promise<(Chapter & Id)[]> {
    return store.cache.dispatch(`${storeName}/forceGetAll`)
  }

  @Action({
    rawError: true,
  })
  async loadList (ids: string[]): Promise<void> {
    const idsToLoad = ids.filter((id) => !this.dataById(id))
    const chapters = await chapterApi.getList(idsToLoad)
    this.setAll_(chapters)
  }

  @Action({
    rawError: true,
  })
  async update (updatedChapter: (Chapter & Id)): Promise<void> {
    this.setIsLoadingUpdate_(true)
    const {
      id, ...chapter
    } = updatedChapter
    await chapterApi.update(id, chapter)
    this.set_(updatedChapter)
    store.cache.delete(`${storeName}/forceGet`, id)
    store.cache.delete(`${storeName}/forceGetAll`)
    this.setIsLoadingUpdate_(false)
  }
}

const chapters = getModule(ChaptersModule)

export default chapters
