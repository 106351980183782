import firebase, {
  db,
} from '@/plugins/firebase'
import {
  Id,
} from '@/types/base'
import {
  Chapter,
} from '@/types/chapter'
import {
  chunkArray,
} from '@/util/common'

const converter: firebase.firestore.FirestoreDataConverter<Chapter> = {
  toFirestore: (chapter: Chapter) => chapter,
  fromFirestore (snapshot: firebase.firestore.QueryDocumentSnapshot, options: firebase.firestore.SnapshotOptions): (Chapter & Id) {
    const chapter = <Chapter>snapshot.data(options)
    return {
      id: snapshot.id,
      ...chapter,
    }
  },
}

class ChapterApi {
  async create (chapter: Chapter): Promise<(Chapter & Id)> {
    const ref = await db.collection(`chapters`)
      .withConverter(converter)
      .add(chapter)
    return {
      id: ref.id,
      ...chapter,
    }
  }

  async delete (id: string): Promise<void> {
    return db.doc(`chapters/${id}`)
      .delete()
  }

  async get (id: string): Promise<(Chapter & Id) | undefined> {
    const snapshot = await db.doc(`chapters/${id}`)
      .withConverter(converter)
      .get()
    const data = snapshot.data()
    if (!data) return
    return <(Chapter & Id)>data
  }

  async getAll (): Promise<(Chapter & Id)[]> {
    const snapshot = await db.collection(`chapters`)
      .withConverter(converter)
      .get()
    return snapshot.docs.map((doc) => <(Chapter & Id)>doc.data())
  }

  async getList (ids: string[]): Promise<(Chapter & Id)[]> {
    const lists = await Promise.all(chunkArray(ids)
      .map(async (chunkedIds) => {
        const snapshot = await db.collection(`chapters`)
          .where(firebase.firestore.FieldPath.documentId(), `in`, chunkedIds)
          .withConverter(converter)
          .get()
        return snapshot.docs.map((doc) => <(Chapter & Id)>doc.data())
      }))
    return lists.flat()
  }

  async update (id: string, chapter: Chapter): Promise<void> {
    return db.doc(`chapters/${id}`)
      .withConverter(converter)
      .update(chapter)
  }
}

export default new ChapterApi()
