import firebase, {
  db,
} from '@/plugins/firebase'
import {
  Id,
} from '@/types/base'
import {
  Word,
} from '@/types/word'
import {
  chunkArray,
} from '@/util/common'

const converter: firebase.firestore.FirestoreDataConverter<Word> = {
  toFirestore: (word: Word) => word,
  fromFirestore (snapshot: firebase.firestore.QueryDocumentSnapshot, options: firebase.firestore.SnapshotOptions): (Word & Id) {
    const word = <Word>snapshot.data(options)
    return {
      id: snapshot.id,
      ...word,
    }
  },
}

class WordApi {
  async create (word: Word): Promise<(Word & Id)> {
    const ref = await db.collection(`words`)
      .withConverter(converter)
      .add(word)
    return {
      id: ref.id,
      ...word,
    }
  }

  async delete (id: string): Promise<void> {
    return db.doc(`words/${id}`)
      .delete()
  }

  async get (id: string): Promise<(Word & Id) | undefined> {
    const snapshot = await db.doc(`words/${id}`)
      .withConverter(converter)
      .get()
    const data = snapshot.data()
    if (!data) return
    return <(Word & Id)>data
  }

  async getAll (): Promise<(Word & Id)[]> {
    const snapshot = await db.collection(`words`)
      .withConverter(converter)
      .get()
    return snapshot.docs.map((doc) => <(Word & Id)>doc.data())
  }

  async getList (ids: string[]): Promise<(Word & Id)[]> {
    const lists = await Promise.all(chunkArray(ids)
      .map(async (chunkedIds) => {
        const snapshot = await db.collection(`words`)
          .where(firebase.firestore.FieldPath.documentId(), `in`, chunkedIds)
          .withConverter(converter)
          .get()
        return snapshot.docs.map((doc) => <(Word & Id)>doc.data())
      }))
    return lists.flat()
  }

  async update (id: string, word: Word): Promise<void> {
    return db.doc(`words/${id}`)
      .withConverter(converter)
      .update(word)
  }
}

export default new WordApi()
