import firebase from '@/plugins/firebase'
import store from '@/store/main'
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule,
} from 'vuex-module-decorators'

@Module({
  namespaced: true,
  name: `auth`,
  store,
  dynamic: true,
})
class AuthModule extends VuexModule {
  // state
  private user_: firebase.User | null = null

  get id (): string | null {
    return this.user_ ? this.user_.uid : null
  }

  get user (): firebase.User | null {
    return this.user_
  }

  // mutations
  @Mutation
  private setProfileMutation (user: firebase.User | null) {
    this.user_ = user
  }

  @Mutation
  private resetMutation () {
    this.user_ = null
  }

  @Action({
    rawError: true,
  })
  async createUserWithEmailAndPassword ({
    email, password,
  } : { email: string, password: string }): Promise<firebase.auth.UserCredential> {
    return firebase.auth()
      .createUserWithEmailAndPassword(email, password)
  }

  @Action({
    rawError: true,
  })
  async signInWithEmailAndPassword ({
    email, password,
  } : { email: string, password: string }): Promise<firebase.auth.UserCredential> {
    return firebase.auth()
      .signInWithEmailAndPassword(email, password)
  }

  @Action({
    rawError: true,
  })
  async signInWithProvider (provider: firebase.auth.AuthProvider): Promise<firebase.auth.UserCredential> {
    return firebase.auth()
      .signInWithPopup(provider)
  }

  @Action({
    rawError: true,
  })
  async setUser (user: firebase.User | null): Promise<void> {
    this.setProfileMutation(user)
  }

  @Action({
    rawError: true,
  })
  async refreshUser (): Promise<void> {
    const user = firebase.auth().currentUser
    if (!user) await this.signOut()
    this.setProfileMutation(user)
  }

  @Action({
    rawError: true,
  })
  updateUser (profile: { displayName?: string | null, photoURL?: string | null }): Promise<void> {
    const user = firebase.auth().currentUser
    if (!user) throw Error(`No user`)
    return user.updateProfile(profile)
  }

  @Action({
    rawError: true,
  })
  async deleteUser (): Promise<void> {
    const user = firebase.auth().currentUser
    if (!user) throw Error(`No user`)
    return user.delete()
  }

  @Action({
    rawError: true,
  })
  async sendPasswordResetEmail (email: string): Promise<void> {
    return firebase.auth()
      .sendPasswordResetEmail(email)
  }

  @Action({
    rawError: true,
  })
  async confirmPasswordReset ({
    actionCode, password,
  }: { actionCode: string, password: string }): Promise<void> {
    return firebase.auth()
      .confirmPasswordReset(actionCode, password)
  }

  @Action({
    rawError: true,
  })
  async updateEmail (email: string): Promise<void> {
    const user = firebase.auth().currentUser
    if (!user) throw Error(`No user`)
    return user.updateEmail(email)
  }

  @Action({
    rawError: true,
  })
  async sendEmailVerification (): Promise<void> {
    const user = firebase.auth().currentUser
    if (!user) throw Error(`No user`)
    return user.sendEmailVerification()
  }

  @Action({
    rawError: true,
  })
  async applyActionCode (actionCode: string): Promise<void> {
    return firebase.auth()
      .applyActionCode(actionCode)
  }

  @Action({
    rawError: true,
  })
  async checkActionCode (actionCode: string): Promise<firebase.auth.ActionCodeInfo> {
    return firebase.auth()
      .checkActionCode(actionCode)
  }

  @Action({
    rawError: true,
  })
  async signOut (): Promise<void> {
    await firebase.auth()
      .signOut()
    this.resetMutation()
  }

  @Action({
    rawError: true,
  })
  async getEmailCredential (email: string, password: string): Promise<firebase.auth.AuthCredential> {
    const user = firebase.auth().currentUser
    if (!user) throw Error(`No user`)
    return firebase.auth.EmailAuthProvider.credential(email, password)
  }

  @Action({
    rawError: true,
  })
  async reauthenticate (creditial: firebase.auth.AuthCredential): Promise<firebase.auth.UserCredential> {
    const user = firebase.auth().currentUser
    if (!user) throw Error(`No user`)
    return user.reauthenticateWithCredential(creditial)
  }

  @Action({
    rawError: true,
  })
  async updatePassword (password: string): Promise<void> {
    const user = firebase.auth().currentUser
    if (!user) throw Error(`No user`)
    return user.updatePassword(password)
  }

  @Action({
    rawError: true,
  })
  async recoverEmail (actionCode: string): Promise<string> {
    const actionCodeInfo = await this.checkActionCode(actionCode)
    const {
      email,
    } = actionCodeInfo.data
    if (!email) throw Error(`No email`)
    await this.applyActionCode(actionCode)
    await this.sendPasswordResetEmail(email)
    return email
  }
}

const auth = getModule(AuthModule)

export default auth
