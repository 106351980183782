
import {
  Id,
} from '@/types/base'
import {
  Grammar,
} from '@/types/grammar'
import store from '@/store/main'
import {
  Action, getModule, Module, Mutation, VuexModule,
} from 'vuex-module-decorators'
import {
  grammarApi,
} from '@/api/database'
import {
  updateDatabaseStoreArray,
} from './util'

const storeName = `grammar`

@Module({
  namespaced: true,
  name: storeName,
  store,
  dynamic: true,
})
class GrammarsModule extends VuexModule {
  // state
  private grammars_: (Grammar & Id)[] = [
  ]

  private isLoadingCreate_ = false

  private isLoadingDelete_ = false

  private isLoadingGet_ = false

  private isLoadingGetAll_ = false

  private isLoadingUpdate_ = false

  // getters
  get data (): (Grammar & Id)[] {
    return this.grammars_
  }

  get dataById () {
    return (id: string) => this.grammars_.find((grammar) => grammar.id === id)
  }

  get isLoadingCreate (): boolean {
    return this.isLoadingCreate_
  }

  get isLoadingDelete (): boolean {
    return this.isLoadingDelete_
  }

  get isLoadingGet (): boolean {
    return this.isLoadingGet_
  }

  get isLoadingGetAll (): boolean {
    return this.isLoadingGetAll_
  }

  get isLoadingUpdate (): boolean {
    return this.isLoadingUpdate_
  }

  // mutations
  @Mutation
  private delete_ (id: string) {
    const found = this.grammars_.find((w) => w.id === id)
    if (!found) return
    const index = this.grammars_.indexOf(found)
    this.grammars_.splice(index, 1)
    // this.grammars_ = this.grammars_.slice(index, 1)
  }

  @Mutation
  private set_ (grammar: (Grammar & Id)) {
    this.grammars_ = updateDatabaseStoreArray(this.grammars_, grammar)
  }

  @Mutation
  private setAll_ (grammars: (Grammar & Id)[]) {
    for (const grammar of grammars) {
      this.grammars_ = updateDatabaseStoreArray(this.grammars_, grammar)
    }
  }

  @Mutation
  private setIsLoadingCreate_ (status: boolean) {
    this.isLoadingCreate_ = status
  }

  @Mutation
  private setIsLoadingDelete_ (status: boolean) {
    this.isLoadingDelete_ = status
  }

  @Mutation
  private setIsLoadingGet_ (status: boolean) {
    this.isLoadingGet_ = status
  }

  @Mutation
  private setIsLoadingGetAll_ (status: boolean) {
    this.isLoadingGetAll_ = status
  }

  @Mutation
  private setIsLoadingUpdate_ (status: boolean) {
    this.isLoadingUpdate_ = status
  }

  // actions
  @Action({
    rawError: true,
  })
  async create (grammar: Grammar): Promise<void> {
    this.setIsLoadingCreate_(true)
    const newGrammar = await grammarApi.create(grammar)
    this.set_(newGrammar)
    store.cache.delete(`${storeName}/forceGetAll`)
    this.setIsLoadingCreate_(false)
  }

  @Action({
    rawError: true,
  })
  async delete (id: string): Promise<void> {
    this.setIsLoadingDelete_(true)
    await grammarApi.delete(id)
    this.delete_(id)
    store.cache.delete(`${storeName}/forceGet`, id)
    store.cache.delete(`${storeName}/forceGetAll`)
    this.setIsLoadingDelete_(false)
  }

  @Action({
    rawError: true,
  })
  async forceGet (id: string): Promise<(Grammar & Id) | undefined> {
    this.setIsLoadingGet_(true)
    const grammar = this.dataById(id) || await grammarApi.get(id)
    if (grammar) this.set_(grammar)
    this.setIsLoadingGet_(false)
    return grammar
  }

  @Action({
    rawError: true,
  })
  async get (id: string): Promise<void> {
    const grammar = <Grammar & Id>(await store.cache.dispatch(`${storeName}/forceGet`, id))
    this.set_(grammar)
  }

  @Action({
    rawError: true,
  })
  async forceGetAll (): Promise<(Grammar & Id)[]> {
    this.setIsLoadingGetAll_(true)
    const grammars = await grammarApi.getAll()
    this.setAll_(grammars)
    this.setIsLoadingGetAll_(false)
    return grammars
  }

  @Action({
    rawError: true,
  })
  async getAll (): Promise<(Grammar & Id)[]> {
    return store.cache.dispatch(`${storeName}/forceGetAll`)
  }

  @Action({
    rawError: true,
  })
  async loadList (ids: string[]): Promise<void> {
    const idsToLoad = ids.filter((id) => !this.dataById(id))
    const grammars = await grammarApi.getList(idsToLoad)
    this.setAll_(grammars)
  }

  @Action({
    rawError: true,
  })
  async update (updatedGrammar: (Grammar & Id)): Promise<void> {
    this.setIsLoadingUpdate_(true)
    const {
      id, ...grammar
    } = updatedGrammar
    await grammarApi.update(id, grammar)
    this.set_(updatedGrammar)
    store.cache.delete(`${storeName}/forceGet`, id)
    store.cache.delete(`${storeName}/forceGetAll`)
    this.setIsLoadingUpdate_(false)
  }
}

const grammars = getModule(GrammarsModule)

export default grammars
