























import Vue from 'vue'
import {
  snackbarStore,
} from '@/store'
import {
  TranslateResult,
} from 'vue-i18n'
import {
  SnackbarMessage,
} from '@/types'

export default Vue.extend({
  data: () => ({
    snackbar: false as boolean,
    timeout: 6000 as number,
    queue: [
    ] as SnackbarMessage[],
    currentMessage: null as SnackbarMessage | null,
    message: null as TranslateResult | null,
    type: null as string | null,
    code: null as string | null,
  }),

  computed: {
    messages (): SnackbarMessage[] {
      return snackbarStore.messageQueue
    },
  },

  watch: {
    async snackbar (snackbar: boolean) {
      if (snackbar && this.currentMessage) return
      this.resetMessage()
      if (!this.queue || this.queue.length === 0) return
      this.loadMessage()
      await this.$sleep(100)
      this.snackbar = true
    },
    messages (messages: SnackbarMessage[]) {
      if (!messages || messages.length === 0) return
      messages.forEach(message => {
        this.queue.push(message)
      })
      snackbarStore.removeSiteMessage()
    },
    queue: {
      deep: true,
      handler (queue: SnackbarMessage[]) {
        if (!queue || queue.length === 0) return
        if (this.snackbar) return
        if (this.currentMessage) return
        this.snackbar = true
      },
    },
  },

  methods: {
    loadMessage () {
      const current = this.queue.shift() || null
      this.currentMessage = current
      if (current) {
        ({
          type: this.type, code: this.code,
        } = current)
        this.message = current.snackMessage || current.message
        this.timeout = current.timeout || 6000
      } else {
        this.resetMessage()
      }
    },
    resetMessage () {
      this.message = null
      this.type = null
      this.code = null
      this.timeout = 6000
      this.currentMessage = null
    },
  },
})
