
import Vue from 'vue'
import Vuex from 'vuex'
import createCache from 'vuex-cache'

Vue.use(Vuex)

const store = new Vuex.Store({
  plugins: [
    createCache({
      timeout: 1000 * 60 * 60, // one hour
    }),
  ],
})

export default store
