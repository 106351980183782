import Vue from 'vue'
import {
  TranslateResult,
} from 'vue-i18n'
import {
  TranslatedVuetifyRule,
} from '@/plugins/vuetify/types'
import './functions'

const globals = Object.freeze({
  rules: {
    required: ((errorMessage?: TranslateResult) => (v: any) => (String(v ?? ``)
      .trim().length > 0) || errorMessage || Vue.i18n.t(`rules.error.required`)) as TranslatedVuetifyRule,
    email: ((errorMessage?: TranslateResult) => (v: any) => (/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(v)) || errorMessage || Vue.i18n.t(`rules.error.email`)) as TranslatedVuetifyRule,
    exactMatch: ((v2: string, errorMessage?: TranslateResult) => (v: string) => (v === v2) || errorMessage || Vue.i18n.t(`rules.error.matchingValues`)) as TranslatedVuetifyRule,
    password: ((errorMessage?: TranslateResult) => (v: string) => (v?.length >= 6) || errorMessage || Vue.i18n.t(`rules.error.password`)) as TranslatedVuetifyRule,
  },
  icons: {
    bank: {
      icon: `mdi-bank`,
      translation: `Bank`,
    },
    bar: {
      icon: `mdi-glass-cocktail`,
      translation: `Bar`,
    },
    cinema: {
      icon: `mdi-filmstrip`,
      translation: `Cinema`,
    },
    school: {
      icon: `mdi-school`,
      translation: `School`,
    },
    home: {
      icon: `mdi-home`,
      translation: `Home`,
    },
  },
})

Vue.prototype.$global = globals

export default globals
