import Vue from 'vue'
import App from '@/App.vue'
// import '@/registerServiceWorker'
import router from '@/router/index.ts'
import store from '@/store/main.ts'
import i18n from '@/locale/index.ts'
import vuetify from '@/plugins/vuetify/index.ts'
import '@/global'
import '@/plugins'

Vue.config.productionTip = false

new Vue({
  render: (h) => h(App),
  router,
  vuetify,
  store,
  i18n,
})
  .$mount(`#app`)
