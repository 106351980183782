import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colors from 'vuetify/lib/util/colors'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: `mdi`, // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: colors.purple.base,
        secondary: colors.purple.darken4,
        tertiary: colors.purple.lighten4,
        accent: colors.orange.base,
        error: colors.red.base,
        info: colors.blue.base,
        success: colors.green.base,
        warning: colors.amber.base,
      },
    },
  },
})
