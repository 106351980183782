import firebase, {
  db,
} from '@/plugins/firebase'
import {
  Id,
} from '@/types/base'
import {
  Grammar,
} from '@/types/grammar'
import {
  chunkArray,
} from '@/util/common'

const converter: firebase.firestore.FirestoreDataConverter<Grammar> = {
  toFirestore: (grammar: Grammar) => grammar,
  fromFirestore (snapshot: firebase.firestore.QueryDocumentSnapshot, options: firebase.firestore.SnapshotOptions): (Grammar & Id) {
    const grammar = <Grammar>snapshot.data(options)
    return {
      id: snapshot.id,
      ...grammar,
    }
  },
}

class GrammarApi {
  async create (grammar: Grammar): Promise<(Grammar & Id)> {
    const ref = await db.collection(`grammars`)
      .withConverter(converter)
      .add(grammar)
    return {
      id: ref.id,
      ...grammar,
    }
  }

  async delete (id: string): Promise<void> {
    return db.doc(`grammars/${id}`)
      .delete()
  }

  async get (id: string): Promise<(Grammar & Id) | undefined> {
    const snapshot = await db.doc(`grammars/${id}`)
      .withConverter(converter)
      .get()
    const data = snapshot.data()
    if (!data) return
    return <(Grammar & Id)>data
  }

  async getAll (): Promise<(Grammar & Id)[]> {
    const snapshot = await db.collection(`grammars`)
      .withConverter(converter)
      .get()
    return snapshot.docs.map((doc) => <(Grammar & Id)>doc.data())
  }

  async getList (ids: string[]): Promise<(Grammar & Id)[]> {
    const lists = await Promise.all(chunkArray(ids)
      .map(async (chunkedIds) => {
        const snapshot = await db.collection(`grammars`)
          .where(firebase.firestore.FieldPath.documentId(), `in`, chunkedIds)
          .withConverter(converter)
          .get()
        return snapshot.docs.map((doc) => <(Grammar & Id)>doc.data())
      }))
    return lists.flat()
  }

  async update (id: string, grammar: Grammar): Promise<void> {
    return db.doc(`grammars/${id}`)
      .withConverter(converter)
      .update(grammar)
  }
}

export default new GrammarApi()
