














import mixins from 'vue-typed-mixins'
import {
  vModel,
} from '@/mixins'
import AppTitle from '@/components/Core/AppTitle.vue'
import {
  authStore,
} from '@/store'
import firebase from '@/plugins/firebase'

export default mixins(vModel)
  .extend({
    components: {
      AppTitle,
    },
    computed: {
      user (): firebase.User | null {
        return authStore.user
      },
    },
  })
